<template>
  <div ref="containerScroll">
    <RevContainer>
      <div class="flex flex-col gap-16 py-16">
        <RevButton
          v-if="isLoadingMore"
          class="self-center"
          :loading="isLoadingConversation"
          size="small"
          variant="secondary"
          @click="emit('load')"
          >{{ i18n(translations.loadMore) }}</RevButton
        >
        <div v-for="(message, index) in messagesDated" :key="message.id">
          <div
            v-if="
              message.day &&
              ((index > 0 && message.day !== messagesDated[index - 1].day) ||
                index === 0)
            "
            class="flex items-center"
          >
            <hr class="bg-static-default-mid m-0 h-1 grow border-none" />
            <RevTag :label="message.day" size="small" variant="secondary" />
            <hr class="bg-static-default-mid m-0 h-1 grow border-none" />
          </div>
          <RevMessage
            :class="[
              {
                'bg-static-default-low': message.role === ROLES.AUTO_MESSAGE,
              },
              'rounded-md px-16 pt-16',
            ]"
            :label="message.sender ?? ''"
            :tag="
              message.role === ROLES.AUTO_MESSAGE
                ? i18n(translations.automatedMessage)
                : ''
            "
            :timestamp="
              message.timestamp ? new Date(message.timestamp) : undefined
            "
            :variant="getVariant(message.role)"
          >
            <!-- eslint-disable vue/no-v-html -->
            <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
            <span v-html="message.content" />
            <!-- eslint-enable vue/no-v-html -->
            <template #documents v-if="message.attachments.length > 0">
              <RevDocument
                v-for="attachment in message.attachments"
                :key="attachment.name"
                :name="attachment.name"
                :url="attachment.href"
              />
            </template>
          </RevMessage>
        </div>
      </div>
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUpdated, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevDocument } from '@ds/components/Document'
import { RevMessage } from '@ds/components/Message'
import { RevTag } from '@ds/components/Tag'

import {
  ROLES,
  type Role,
} from '../OrderConversation/OrderConversation.constants'

import translations from './Conversation.translations'

interface Message {
  id: string
  content: string
  isoDate: string
  sender: string | null
  role: Role
  attachments: {
    name: string
    extension: string | undefined
    type: 'external'
    href: string
  }[]
  timestamp?: number
  day?: string
}

const props = defineProps<{
  messages: Message[]
  isLoadingMore: boolean
  isLoadingConversation: boolean
  role: Role
}>()

const emit = defineEmits(['load'])

const i18n = useI18n()

const scrollBottom = ref(false)
const isClientSide = ref(false)
const containerScroll = ref<HTMLElement | null>(null)

const getVariant = (role: Message['role']) => {
  switch (role) {
    case ROLES.MERCHANT:
      return 'seller'
    case ROLES.STAFF:
    case ROLES.AUTO_MESSAGE:
      return 'backmarket'
    case ROLES.CUSTOMER:
    default:
      return 'customer'
  }
}

watch(
  () => props.messages,
  (newMessages, oldMessages) => {
    if (
      newMessages[newMessages.length - 1].id !==
      oldMessages[oldMessages.length - 1].id
    ) {
      scrollBottom.value = true
    }
  },
)

function scrollBottomConversation() {
  if (containerScroll.value && containerScroll.value.scrollHeight) {
    containerScroll.value.scrollTo({
      top: containerScroll.value.scrollHeight,
    })
  }
}

onUpdated(() => {
  scrollBottomConversation()
})

onMounted(() => {
  scrollBottomConversation()
  isClientSide.value = true
})

const messagesDated = computed(() => {
  if (isClientSide.value) {
    return props.messages.map((message) => ({
      ...message,
      timestamp: Date.parse(message.isoDate),
      day: i18n.date(new Date(message.isoDate), { dateStyle: 'medium' }),
    }))
  }

  return props.messages
})
</script>
