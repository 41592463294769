import type { LinkExternal, Locale } from '@backmarket/http-api'
import type {
  BuybackOrderConversation,
  BuybackOrderConversationMetadata,
  BuybackOrderMessage,
} from '@backmarket/http-api/src/api-specs-buyback/models'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import { hashObjects } from '@backmarket/utils/object/hashObjects'

import {
  ROLES,
  type Role,
} from '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.constants'
import { getFilenameFromUrl } from '@backmarket/nuxt-layer-buyback/utils/helpers'

export const detailsAdapter = (
  details: BuybackOrderConversationMetadata,
  role: Role,
  id: string,
  i18n: I18n,
  locale: Locale,
) => {
  let recipient = details.id.toString() || ''
  let backLink = ''

  if (role === ROLES.MERCHANT) {
    recipient = `${details?.customer?.firstName} ${details?.customer?.lastName}`
    backLink = `/buyback/orders/${id}`
  }

  if (role === ROLES.CUSTOMER) {
    recipient = details?.refurbisher?.companyName ?? ''
    backLink = `/${locale}/dashboard/sales`
  }

  if (role === ROLES.STAFF) {
    recipient = details.id.toString()
    backLink = `/bo_admin/buyback/orders/${id}`
  }

  return {
    image: details.product.image.light,
    title: details.product.title,
    price: i18n.price(details.price),
    counterOfferPrice: details.counterOfferPrice
      ? i18n.price(details.counterOfferPrice)
      : '',
    status: details.state,
    recipient,
    backLink,
    showMessageBar: details.isAllowedToPostMessage,
  }
}

const messageAdapter = (message: BuybackOrderMessage) => {
  const { content, date: isoDate, attachments } = message
  const id = hashObjects({ content, isoDate })

  const formattedAttachments =
    attachments.map((file: LinkExternal) => {
      const { name, extension } = getFilenameFromUrl(file.href)

      return {
        ...file,
        name,
        extension,
      }
    }) || []

  return {
    id,
    content,
    isoDate,
    sender: message.author?.name || null,
    role: message.author.role,
    attachments: formattedAttachments,
  }
}

export const conversationAdapter = (
  conversation: BuybackOrderConversation,
  storedMessages: BuybackOrderMessage[],
) => {
  const { count } = conversation
  if (count === 0) {
    return {
      count: 0,
      next: null,
      previous: null,
      messages: [],
    }
  }

  const getPage = (url: string) => {
    if (url === null) return null

    return new URL(url).searchParams.get('page')
  }

  const next = conversation.next ? getPage(conversation.next) : null
  const previous = conversation.previous ? getPage(conversation.previous) : null

  const filteredArray = storedMessages.filter((message, index) => {
    return (
      index ===
      storedMessages.findIndex(
        (existMessage) => message.date === existMessage.date,
      )
    )
  })

  const messages = filteredArray
    .map((message) => messageAdapter(message))
    .reverse()

  return {
    count,
    next,
    previous,
    messages,
  }
}
